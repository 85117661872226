<template>
    <div class="container">
        <div id="alertsModal" class="modal fade">
            <div class="modal-dialog" role="document">
                <div class="modal-content"  v-if="modalVisible">
                    <header class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" @click="$emit('close')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                        <h4 v-if="modalData.alertType === 'scam'" class="modal-title">Comcast Security Alerts</h4>
                        <h4 v-if="modalData.alertType === 'phishing'" class="modal-title">Comcast Phishing Alerts</h4>
                    </header>
                    <section class="modal-body">
                        <h3 style="word-wrap:break-word;">{{modalData.title}}</h3>
                        <p>{{modalData.details}}</p>
                    </section>
                </div>
            </div>
        </div>
        <section class="row">
            <div class="col-xs-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h2>Comcast Security Alerts</h2>
                    </div>
                    <div class="panel-body table-responsive"
                         id="comcast-security-alerts"
                         v-infinite-scroll="loadMoreAlerts"
                         :infinite-scroll-disabled="scams.busy || scams.totalPages <= scams.pageNumber"
                         infinite-scroll-distance="10"
                         :infinite-scroll-immediate-check="false"
                         infinite-scroll-throttle-delay="1000"
                    >
                        <table class="table table-bordered" id="scamTable">
                            <thead>
                            <tr>
                                <th class="alerts--date">Date</th>
                                <th class="alerts--level">Threat&nbsp;Level</th>
                                <th class="alerts--title">Title</th>
                            </tr>
                            </thead>
                            <tbody  style="font-weight: 300;">
                                <tr  v-for="scam in validatedScams">
                                    <td class="alerts--date">{{scam.alertDate}}</td>
                                    <td class="alerts--level">{{scam.threatLevel}}</td>
                                    <td class="alerts--title">
                                        <a @click="openModal(scam)" style="cursor: pointer;">{{scam.subject}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

        <section class="row">
            <div class="col-xs-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h2>Top Phishing Scams</h2>
                    </div>
                    <div class="panel-body table-responsive"
                         id="top-phishing-scams"
                         v-infinite-scroll="loadMorePhishing"
                         :infinite-scroll-disabled="phishing.busy || phishing.totalPages <= phishing.pageNumber"
                         infinite-scroll-distance="10"
                         :infinite-scroll-immediate-check="false"
                         infinite-scroll-throttle-delay="1000"
                    >
                        <table class="table table-bordered" id="phishingTable">
                            <thead>
                            <tr>
                                <th class="alerts--date">Date</th>
                                <th class="alerts--level">Threat&nbsp;Level</th>
                                <th class="alerts--from">From</th>
                                <th class="alerts--title">Subject Line</th>
                            </tr>
                            </thead>
                            <tbody style="font-weight: 300;">
                                <tr v-for="scam in validatedPhishing">
                                    <td class="alerts--date">{{scam.alertDate}}</td>
                                    <td class="alerts--level">{{scam.threatLevel}}</td>
                                    <td class="alerts--from">{{scam.mailFrom}}</td>
                                    <td class="alerts--title"><a @click="openModal(scam)" style="cursor: pointer;">{{scam.subject}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <section class="row">
            <div class="col-xs-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h2>Comcast Threat Levels</h2>
                    </div>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <h4 class="text-red"><i class="fa fa-exclamation-triangle"></i> Critical</h4>
                            <p>There are currently multiple serious security issues that may threaten Comcast Customers. There may be a high prevalence of phishing or virus activity, or a specific issue that is particularly widespread and/or dangerous. Customers are advised to make sure all security software is up-to-date, exercise extreme caution on the Internet, and check the Security Channel often for updates and any specific actions that should be taken.</p>
                        </li>
                        <li class="list-group-item">
                            <h4 class="text-orange"><i class="fa fa-exclamation-triangle"></i> High</h4>
                            <p>The general level of security threat is currently elevated. There may be a new operating system vulnerability or a new form of spyware circulating on the Internet. Customers are encouraged to ensure all security software is up-to-date, check the Security Channel for any specific actions that should be taken, and maintain a high level of vigilance when using the Internet.</p>
                        </li>
                        <li class="list-group-item">
                            <h4 class="text-yellow"><i class="fa fa-exclamation-triangle"></i> Medium</h4>
                            <p>The general level of security threat is currently at a normal level. Threats exist, but none have been determined to have dire consequences. Customers with updated security software should be fully protected, but are encouraged to remain vigilant as always for suspicious activity.</p>
                        </li>
                        <li class="list-group-item">
                            <h4 class="text-green"><i class="fa fa-exclamation-triangle"></i> Low</h4>
                            <p>The general level of security threat is currently lower than normal. The overall quantity and/or consequences of security issues are low, but threats do still exist. Customers should make sure security software is up-to-date and check the Security Channel for updated threat levels.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    const axios = require('axios');
    import infiniteScroll from 'vue-infinite-scroll';

    export default {
        directives: {infiniteScroll},
        created() {
            if (typeof(window.__scams) === 'object' && Array.isArray(window.__scams.alerts)) {
                this.$set(this.scams, 'alerts', window.__scams.alerts);
                this.scams.pageNumber = window.__scams.page_number;
                this.scams.totalPages = window.__scams.total_pages;
            }

            if (typeof(window.__phishing) === 'object' && Array.isArray(window.__phishing.alerts)) {
                this.$set(this.phishing, 'alerts', window.__phishing.alerts);
                this.phishing.pageNumber = window.__phishing.page_number;
                this.phishing.totalPages = window.__phishing.total_pages;
            }
        },
        computed: {
            validatedPhishing: function () {
                return this.phishing.alerts.filter(this.validatePhishingAlert)
            },
            validatedScams: function () {
                return this.scams.alerts.filter(this.validateScamAlert)
            }
        },
        data: function() {
            return {
                scams: {
                    alerts: [],
                    busy: false,
                    pageNumber: 0,
                    totalPages: null
                },
                phishing: {
                    alerts: [],
                    busy: false,
                    pageNumber: 0,
                    totalPages: null
                },
                modalVisible: false,
                modalData: null,
            }
        },
        methods: {
            getPhishing() {
                if (this.phishing.totalPages !== null && this.phishing.totalPages <= this.phishing.pageNumber) {
                    return;
                }

                return axios.get(`/phishing/${this.phishing.pageNumber + 1}`)
                    .then( response => {
                        this.$set(this.phishing, 'alerts', this.phishing.alerts.concat(response.data.alerts));
                        this.phishing.pageNumber = response.data.page_number;
                        this.phishing.totalPages = response.data.total_pages;
                    })
                    .catch(error => { console.log(error); });
            },
            getScams() {
                if (this.scams.totalPages !== null && this.scams.totalPages <= this.scams.pageNumber) {
                    return;
                }

                return axios.get(`/scams/${this.scams.pageNumber + 1}`)
                    .then( response => {
                        this.$set(this.scams, 'alerts', this.scams.alerts.concat(response.data.alerts));
                        this.scams.pageNumber = response.data.page_number;
                        this.scams.totalPages = response.data.total_pages;
                    })
                    .catch(error => { console.log(error); });
            },
            loadMoreAlerts: function() {
                this.scams.busy = true;

                setTimeout(() => {
                    this.getScams()
                        .finally(() => { this.scams.busy = false; });
                }, 500);
            },
            loadMorePhishing: function() {
                this.phishing.busy = true;

                setTimeout(() => {
                    this.getPhishing()
                        .finally(() => { this.phishing.busy = false; });
                }, 500)
            },
            openModal(data) {
                this.modalData = data;
                console.log(this.modalData);
                this.modalVisible = true;
                $("#alertsModal").modal('show');
            },
            validateScamAlert(alert) {
                return this.validateTypes(alert, {
                    alertDate: 'string',
                    threatLevel: 'string',
                    title: 'string'
                });
            },
            validatePhishingAlert(alert) {
                return this.validateTypes(alert, {
                    alertDate: 'string',
                    threatLevel: 'string',
                    mailFrom: 'string',
                    subject: 'string'
                });
            },
            validateTypes(object, map) {
                if (typeof(object) !== 'object') {
                    return false;
                }

                for (const [key, type] of Object.entries(map)) {
                    if (typeof(object[key]) !== type) {
                        return false;
                    }
                }

                return true;
            }
        }

    }
</script>
