require('./bootstrap');

window.Vue = require('vue');

Vue.component('alerts', require('./components/Alerts.vue').default);

new Vue({
    el: '#main-content',
});


